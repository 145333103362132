<template>
    <b-card no-body>

        <b-modal id="create-or-update" :title="editing ? 'Güncelle' : 'Oluştur'" no-close-on-esc no-close-on-backdrop>
            <material-create-or-update v-model="material"></material-create-or-update>
            <template #modal-footer><v-executer :loading="loading" :editing="editing" :action="createOrUpdate"></v-executer></template>
        </b-modal>

        <v-table    ref="table"
                    :add-callback="createNew"
                    :items-provider="provider"
                    :fields="fields"
                    :buttons="buttons"
                    search
                    pagination="server"></v-table>
    </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { MaterialApi } from '@/api/material'
import MaterialCreateOrUpdate from './components/MaterialCreateOrUpdate.vue'
export default {
    components:{
        BCard,
        MaterialCreateOrUpdate
    },
    data(){
        return{
            editing:false,
            loading:false,
            fields:[
                {name:'Ürün Kodu',key:'Code',sortable:true, thClass:'w-25'},
                {name:'Ürün Adı',key:'Name',sortable:true, thClass:'w-75'},
            ],
            buttons:[
                { text:"Düzenle",key:"edit",icon:"Edit2Icon",variant:"secondary",action:this.edit },
                { text:"Sil",key:"delete",icon:"TrashIcon",variant:"danger",action:this.delete },
            ],
            material:{
                Id:null,
                Name: null,
                Code:null
            }
        }
    },
    methods:{
        clearForm(){
            this.editing = false
            this.material.Id = null
            this.material.Name = null
            this.material.Code = null
            
        },
        provider(data){
            return MaterialApi.Table(data)
        },
        createNew(){
            this.clearForm()
            this.$showModal('create-or-update')
        },
        edit(item){
            this.clearForm()
            this.editing = true
            this.material.Id = item.Id
            this.material.Name = item.Name
            this.material.Code = item.Code
            this.$showModal('create-or-update')
        },
        createOrUpdate(){
            this.loading = true
            MaterialApi.CreateOrUpdate(this.editing,this.material).then(res=>{
                this.$result(res)
                this.loading = false
                if(res.data.Success){
                    this.$refs.table.refresh()
                    this.$hideModal('create-or-update')
                }
            }).catch(err=>{
                this.loading = false
                this.$result(null,err)
            })
        },
        delete(item){
            this.$confirm('Silmek istediğinize emin misiniz?',`Bu işlem ${item.Name} adlı malzemeyi silecektir ve geri alınamaz.`).then(result=>{
                if(result){
                    MaterialApi.Delete(item.Id).then(res=>{
                        if(res.data.Success) {
                            this.$refs.table.refresh()
                        }
                        this.$result(res)
                    }).catch(err=>{
                        this.$result(null,err)
                    })
                }
            })
        }
    }
}
</script>

<style>

</style>