<template>
    <div class="row">
        <div class="col-12 mb-1">
            <label class="form-label">Ürün Kodu</label>
            <input type="text" class="form-control" v-model="value.Code" placeholder="Ürün Kodu" />
        </div>
        <div class="col-12 mb-1">
            <label class="form-label">Ürün Adı</label>
            <input type="text" class="form-control" v-model="value.Name" placeholder="Ürün Adı" />
        </div>
      
    </div>
</template>

<script>
export default {
    props:{
        value:Object
    },
    data(){
        return{
           
        }
    },
}
</script>

<style>

</style>